@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@300&");

.container {
	align-self: center;
	width: 90%;
	max-width: 850px;
}

.card {
	display: flex;
	flex-direction: column;
	padding: 2rem;
	background-color: rgba(190, 49, 49, 0.438);
	border-radius: 1rem;
	backdrop-filter: blur(8px);
	box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
	margin: 2rem auto;
	width: 100%;
	max-width: calc(100% - 4rem);
	align-items: center;
	text-align: center;
}

.profile-pic {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 1rem;
}

.profile-pic img {
	width: 8rem;
	height: auto;
	margin-bottom: 1rem;
	animation: fadeIn 1s ease-in-out;

	padding: 1rem;
	border-radius: 1rem;
	box-shadow: 0 0 2rem rgba(0, 0, 0, 0.5);
	background-color: rgba(0, 0, 0, 0.6);
}

h2 {
	color: #fcfcfc;
}

.profile-text {
	padding-left: 2rem;
	padding-right: 2rem;
	text-align: center;
}

.profile-text p {
	margin: 0;
	color: #868686;
}

.hallow-buttons {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin-top: 2rem;
}

.hallow-buttons a {
	text-decoration: none;
	text-decoration: none;
	background-color: #00000000;
	color: #fcfcfc;
	border-color: #333333;
	border-width: 30px;
	padding: 1rem 2rem;
	border-radius: 0.5rem;
	margin-right: 1rem;

	box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.3);
	transition: background-color 0.3s ease-in;
}

.hallow-buttons a:hover {
	background-color: #fcfcfc;
	color: #333;
}

.buttons {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.buttons a {
	text-decoration: none;
	background-color: #fcfcfc;
	color: #000000ff;
	padding: 1rem 2rem;
	border-radius: 0.5rem;
	margin-right: 1rem;
	margin-bottom: 1rem;

	box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.3);
	transition: background-color 0.3s ease-in;
}

.buttons a:hover {
	background-color: #00000000;
	color: #fcfcfc;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
