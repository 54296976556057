@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@300&");

#root {
	width: 60vw;

}

#chat {
	background-color: #222222;
	color: white;
	font-family: "JetBrains Mono", monospace;
	width: 100%;
	border-radius: 1rem;
}

#chat-room {
	padding: 0;
	margin: 0;
	flex-direction: column;
}


#chatroom-section {
	width: 100%;
	overflow-y: hide;
}

#messages {
	flex-direction: column;
	width: 100%;
	height: 80vh;
	overflow-y: auto;
	scrollbar-color: #444 #222;
	scrollbar-width: thin;
}

#sticky-input {
	position: sticky;
	bottom: 0;
	width: 100%;
	background: #222222;
	flex-direction: row;
}

#message-input {
	width: 70%;
	padding: .7rem;
	box-sizing: border-box;
	background: #222222;
	border: 1px solid #444;
	color: #fcfcfc;
	font-family: "JetBrains Mono", monospace;
}

#message-input:focus {
	outline: none;
}

#message-submit {
	width: 15%;
	font-family: "JetBrains Mono", monospace;
	background: #444;
	color: #fcfcfc;
	padding-top: .75rem;
	padding-bottom: .75rem;
	margin: 8px 0;
	border: none;
	cursor: pointer;
}

#message-submit:hover {
	background: #555;
	transition: background .5s;
}

#sign-out {
	width: 15%;
	font-family: "JetBrains Mono", monospace;
	background: #444;
	color: #fcfcfc;
	padding-top: .75rem;
	padding-bottom: .75rem;
	margin: 8px 0;
	border-top-right-radius: .25rem;
	border-bottom-right-radius: .25rem;
	border: none;
	cursor: pointer;
}

#sign-out:hover {
	background: #555;
	transition: background .5s;
}

#chat-room {
	color: white;
	padding-right: 2rem;
	padding-left: 2rem;	
}

.message-photoURL {
	width: 30px;
	height: 30px;
	border-radius: .25rem;
}

.message-content {
	margin-left: .5rem;
	display: flex;
	flex-direction: row;
}

.message-chatname {
	margin-left: .25rem;
}

.message-sent {
	.message-chatname {
		text-shadow: 0 0 .5rem #00bbff;;
	}
}

.message-received {
	.message-chatname {
		text-shadow: .1rem .1rem .5rem #ff00bb;
	}
}

.message-same-author {
	.message-chatname {
		display: none;
	}
	.message-photoURL {
		display: none;
	}	
}

.message-image {
	padding-top: .5rem;
	max-width: 30vw;
	max-height: 50vh;
}

.message-video {
	padding-top: .5rem;
	max-width: 30vw;
	max-height: 50vh;
}

.message-direct-content {
	word-wrap: break-word;
	overflow: hidden;
}

.message-new-author {
	padding-top: 1rem;
}

.message-inline-link {
	color: rgb(11, 163, 0);
	width: fit-content;
	word-wrap: break-word;
	overflow: hidden;

}

.message-text-portion {
	display: inline;
}
