@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@300&");

body {
	margin: 0;
	padding: 0;
	background-size: 800% 800%;
	display: flex;
	height: 100vh;
	font-family: "JetBrains Mono", monospace;
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.93);
	overflow-y: auto;
}

.container {
	position: relative;
	width: 90%;
	max-width: 850px;
}

.res-card {
	display: flex;
	flex-direction: column;
	padding: 2rem;
	background-color: rgba(190, 49, 49, 0.438);
	border-radius: 1rem;
	backdrop-filter: blur(8px);
	box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
	margin: 2rem auto;
	width: 100%;
	max-width: calc(100% - 4rem);
}

.res-list-container {
	display: flex;
	flex-direction: column;
	width: 100%
}

h2 {
	color: #fcfcfc;
}

.res-list-title {
	color: #fcfcfc;
}

.res-list {
	border-radius: 0.5rem;
	background-color: rgba(0, 0, 0, 0.4);
	backdrop-filter: blur(8px);
	box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
}

.res-list li {
	color: #fcfcfc;
	justify-content: left;
	text-align: left;
	width: 100%
}

.res-list li a {
	color: #b49dfe;
	margin-right: .5rem;
}

.hallow-buttons {
	display: flex;
	flex-wrap: wrap;
	margin-top: 2rem;
}

.hallow-buttons a {
	text-decoration: none;
	text-decoration: none;
	background-color: #00000000;
	color: #fcfcfc;
	border-color: #333333;
	border-width: 30px;
	padding: 1rem 2rem;
	border-radius: 0.5rem;
	margin-right: 1rem;

	box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.3);
	transition: background-color 0.3s ease-in;
}

.hallow-buttons a:hover {
	background-color: #fcfcfc;
	color: #333;
}
